/* Slideshow */

.slideshow {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.slideshowSlider {
  width: 100%;
}

.slide {
  width: 100%;
  flex: none;
}

.slideshowDots {
  text-align: center;
  white-space: nowrap;
}
.slideshowDot {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  cursor: pointer;
  margin: 2%;
  background-color: white;
  /* match purple3.600 in theme.ts */
  border-color: #583f73;
  border-width: 1px;
}

.slideshowDotActive {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  cursor: pointer;
  margin: 2%;
  /* match purple3.600 in theme.ts */
  background-color: #583f73;
}
