* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

body {
  /* prevent long press menu on ios */
  -webkit-touch-callout: none;
  /* disable text selection */
  -webkit-user-select: none;
}

.modal_uiv2 {
  font-family: 'Public Sans', 'Roboto', sans-serif;
}

.modal_uiv2 input,
textarea {
  border-radius: 10px;
  border: 2px solid #e5e5e5;
  font-size: 16px;
  margin-bottom: 18px;
  padding: 12px;
  font-weight: 300;
}

.modal_uiv2 p {
  font-size: 16px;
  color: black;
  font-weight: 500;
  margin-bottom: 6px;
}

.link {
  text-decoration: underline;
}

/* ReactPlayer */

.player-wrapper {
  position: relative;
  /* padding-top: 56.25%; */
  /* Player ratio: 100 / (1280 / 720) */
  width: 100%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
