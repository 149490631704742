.input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid black;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}
.input:focus {
  outline: 1px solid #583f73 !important;
}

.react-datepicker__input-container .input {
  padding: 5px;
  text-align: center;
}

.date-picker-calendar .react-date-input {
  border: 1px solid #583f73 !important;
}

.date-picker-calendar .react-datepicker__day--selected,
.date-picker-calendar .react-datepicker__day--selected:hover {
  background-color: #583f73 !important;
  color: white !important;
  border-radius: 50% !important;
}

.date-picker-calendar .react-datepicker__header,
.date-picker-calendar .react-datepicker__header:hover {
  background-color: #e8e2ef !important;
  border-bottom: 1px solid #583f73 !important;
}

.date-picker-calendar .react-datepicker__current-month {
  font-size: 14px;
  font-weight: normal;
}
.date-picker-calendar .react-datepicker__navigation--previous {
  /* position: absolute; */
  top: 14px;
  /* transform: translateY(-50%); */
  border-color: transparent black transparent transparent;
  border-width: 4px;
}
.date-picker-calendar .react-datepicker__navigation--next {
  top: 14px;
  border-color: transparent transparent transparent black;
  border-width: 4px;
}

.date-picker-calendar .react-datepicker__navigation--previous:active,
.date-picker-calendar .react-datepicker__navigation--previous:focus {
  border-color: transparent black transparent transparent;
}

.date-picker-calendar .react-datepicker__navigation--next:active,
.date-picker-calendar .react-datepicker__navigation--next:focus {
  border-color: transparent transparent transparent black;
}
